import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Card, CardBody } from 'design-react-kit';

const messages = defineMessages({
  organizationType: {
    id: 'organizationType',
    defaultMessage: 'Tipologia di organizzazione',
  },
  areaRiferimento: {
    id: 'areaRiferimento',
    defaultMessage: 'Area di riferimento',
  },
});

const PNOfficeInfo = ({ content }) => {
  const intl = useIntl();

  return (
    content?.subjects?.length > 0 && (
      <>
        {/* AREA DI RIFERIMENTO */}
        <RichTextSection
          tag_id={'title-office-area-riferimento'}
          field="text"
          title={intl.formatMessage(messages.areaRiferimento)}
          show_title={true}
        >
          <div className="col-lg-6 col-md-10 col-12">
            <Card
              className="card-bg card-teaser card-teaser-danger rounded my-3  
               shadow"
              noWrapper={false}
              space
              tag="div"
            >
              <CardBody>
                {content?.subjects?.map((subject) => (
                  <li> {subject}</li>
                ))}
              </CardBody>
            </Card>
          </div>
        </RichTextSection>
      </>
    )
  );
};

PNOfficeInfo.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNOfficeInfo;
