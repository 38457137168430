/**
 * NewsItemView view component.
 * @module components/theme/View/PNOfficeView
 */

import { Col, Container, Row } from 'design-react-kit';
import {
  ContentImage,
  ContentTypeViewSections,
  Metadata,
  NewsItemPlaceholderAfterContent,
  PageHeader,
  RelatedItems,
  SkipToMainContent,
  useSideMenu,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  PNAlertBehavior,
  PNAttachments,
} from 'comune-pordenone-plone-theme/components/View';
import React, { createRef, useState } from 'react';

import PNOfficeParentOffice from './PNOfficeParentOffice';
import PNOfficeCompetencesView from './PNOfficeCompetencesView';
import PNOfficeContacts from './PNOfficeContacts';
import PNOfficeDates from './PNOfficeDates';
import PNOfficeInfo from './PNOfficeInfo';
import PNOfficeLocations from './PNOfficeLocations';
import PNOfficePeople from './PNOfficePeople';
import PNOfficeServices from './PNOfficeServices';
import PNOfficeSubOffices from './PNOfficeSubOffices';
import PropTypes from 'prop-types';
import { readingTime } from '../ViewUtils';

export const OfficeViewSectionsOrder = [
  { /* PARENTOFFICE */ component: PNOfficeParentOffice },
  { /* COMPETENCES */ component: PNOfficeCompetencesView },
  { /* INFO */ component: PNOfficeInfo },
  { /* PEOPLE */ component: PNOfficePeople },
  { /* CONTACTS */ component: PNOfficeContacts },
  { /* SERVICES */ component: PNOfficeServices },
  { /* LOCATIONS */ component: PNOfficeLocations },
  { /* ATTACHMENTS */ component: PNAttachments },
  { /* SUBOFFICES */ component: PNOfficeSubOffices },
  { /* DATES */ component: PNOfficeDates },
  { /* METADATA */ component: Metadata },
];

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNOfficeView = ({ content, location }) => {
  const [readingtime, setReadingtime] = useState(0);
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);

  const modifiedContent = {
    ...content,
    tassonomia_argomenti:
      content?.organizationType?.length > 0
        ? content.organizationType
        : undefined,
  };

  return (
    <>
      <Container className="my-4 newsitem-view">
        <div className="px-4 px-lg-0">
          <SkipToMainContent />
          <PageHeader
            content={modifiedContent}
            readingtime={readingtime}
            showreadingtime={true}
            showdates={true}
            showtassonomiaargomenti={true}
          />
          <PNAlertBehavior
            text={content?.alertText}
            expirationDate={content?.alertExpiration}
          />
          {/* HEADER IMAGE */}
          <ContentImage content={content} position="afterHeader" />
          <Row className="border-top row-column-border row-column-menu-left">
            <Col tag="aside" lg={4}>
              {__CLIENT__ && (
                <SideMenu data={sideMenuElements} content_uid={content?.UID} />
              )}
            </Col>
            <section
              ref={documentBody}
              id="main-content-section"
              className="col-lg-8 it-page-sections-container"
            >
              {/* SEZIONI */}
              <ContentTypeViewSections
                content={content}
                defaultSections={OfficeViewSectionsOrder}
              />
            </section>
          </Row>
          <NewsItemPlaceholderAfterContent content={content} />
          <RelatedItems content={content} />
        </div>
      </Container>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNOfficeView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    effective: PropTypes.string,
    expires: PropTypes.string,
    image: PropTypes.object,
    image_caption: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
    dataset: PropTypes.shape({
      data: PropTypes.string,
    }),
    modified: PropTypes.string,
    rights: PropTypes.string,
    luoghi_correlati: PropTypes.array,
    related_news: PropTypes.array,
    tipologia_ufficio: PropTypes.shape({
      title: PropTypes.string,
      token: PropTypes.string,
    }),
  }).isRequired,
};

export default PNOfficeView;
